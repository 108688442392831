<template>
  <b-modal
    :visible="visible"
    title="Adicionar responsável"
    size="lg"
    hide-footer
    @hide="close"
  >
    <b-form @submit.prevent="submit">
      <form-input
        v-model="responsavel"
        placeholder="Informe o responsável pela ligação"
        label="Responsável:"
      />

      <b-button variant="primary" type="submit">SALVAR</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FormInput from "../form/FormInput.vue";

export default {
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  components: { FormInput },
  name: "WeCallYouList",
  data() {
    return {
      responsavel: "",
    };
  },
  computed: {
    ...mapState("weCallYou", ["scheduling"]),
  },
  methods: {
    ...mapActions("weCallYou", ["addAccountable"]),

    async submit() {
      if (this.responsavel) {
        await this.addAccountable({
          responsavel: this.responsavel,
          id: this.scheduling.id,
        });
      }
      this.close();
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>

<style></style>
