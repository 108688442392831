<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Agendamentos</h4>
        </div>
        <b-card-text class="d-flex flex-column">
          <b-table
            class="lvj-table"
            :items="schedulingDateFormat"
            :fields="fields"
            :busy="isLoading"
            bordered
            striped
            hover
          >
            <template #cell(actions)="row">
              <b-button
                v-if="
                  !row.item.responsavel &&
                  row.item.atendido === 'Aguardando ligação'
                "
                variant="danger"
                size="sm"
                class="mr-2"
                title="Adicionar responsável"
                @click="openAccountableModal(row.item)"
              >
                <b-icon-telephone />
              </b-button>
              <b-button
                v-if="
                  row.item.responsavel &&
                  row.item.atendido === 'Aguardando ligação'
                "
                variant="warning"
                size="sm"
                class="mr-2"
                title="Marcar como ligação realizada"
                @click="openMarkCompletedModal(row.item)"
              >
                <b-icon-telephone-fill />
              </b-button>
              <b-button
                v-if="row.item.atendido === 'Ligação realizada'"
                variant="success"
                size="sm"
                class="mr-2"
                title="Ver detalhes"
                @click="changeRoute(row.item)"
              >
                <b-icon-check />
              </b-button>
            </template>
          </b-table>
          <b-button @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>
      </b-col>
    </b-row>

    <add-accountable v-model="showAccountableModal" />
    <mark-completed v-model="showMarkCompletedModal" />
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AddAccountable from "../../components/weCallYou/AddAccountable.vue";
import MarkCompleted from "../../components/weCallYou/MarkCompleted.vue";

export default {
  name: "WeCallYouList",
  data() {
    return {
      page: 2,
      showAccountableModal: false,
      showMarkCompletedModal: false,
      fields: [
        {
          key: "nome",
          sortable: true,
        },
        {
          key: "telefone",
          sortable: true,
        },
        {
          key: "atendido",
          label: "Situação",
          sortable: true,
        },
        {
          key: "responsavel",
          label: "Responsável",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Data Recebido",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  components: { AddAccountable, MarkCompleted },
  computed: {
    ...mapState("weCallYou", ["scheduling", "schedules", "isLoading"]),

    schedulingDateFormat() {
      return this.schedules.map((e) => ({
        ...e,
        atendido: e.atendido ? "Ligação realizada" : "Aguardando ligação",
        created_at: new Date(
          new Date(e.created_at).getTime() + 3 * (60 * 60 * 1000)
        ).toLocaleString("pt-BR"),
      }));
    },
  },
  methods: {
    ...mapActions("weCallYou", [
      "getAllSchedules",
      "getMoreSchedules",
      "addAccountable",
      "markCompleted",
    ]),
    ...mapMutations("weCallYou", ["setOneScheduling"]),

    openAccountableModal(scheduling) {
      this.setOneScheduling(scheduling);
      this.showAccountableModal = true;
    },
    openMarkCompletedModal(scheduling) {
      this.setOneScheduling(scheduling);
      this.showMarkCompletedModal = true;
    },
    changeRoute(scheduling) {
      this.setOneScheduling(scheduling);
      this.$router.push(`ligamos-para-voce/${scheduling.id}/detalhes`);
    },
    async getMore() {
      const { page } = this;
      await this.getMoreSchedules(page);
      this.page = page + 1;
    },
  },
  async created() {
    await this.getAllSchedules();
  },
};
</script>

<style></style>
